/*******************************************************************************
  DownloadRounds.js

  Author: Mark Duckworth; Advait; 

*******************************************************************************/

import React from 'react'
import { API } from 'aws-amplify'
// ability to save a file we download through an API
import FileSaver from 'file-saver'
// ensure a filename does not have invalid characters
import sanitize from 'sanitize-filename'
import '../index.css'

/**
 * Component to download a usage report. Text fields for entering
 * the start and end dates, and a download button.
 */
function DownloadUsageReport(props) {

    /**
     * Click handler to perform the file download
     * @param {*} event 
     */
    function doDownloadUsage(event) {
        event.preventDefault();
        const startDate = document.getElementById("usageStart").value;
        const endDate = document.getElementById("usageEnd").value;
        const api_path = `/usagereport?start=${startDate}&end=${endDate}`;
        const config = {
            responseType: 'blob',
            response: true,
        };
  
        // Create a filename for the saved file on the local machine
        let filename = `usage_report_${startDate}_to_${endDate}.csv`;
        filename = filename.replace(/ /g, '_');
        filename = sanitize(filename); //remove any illegal filename characters
  
        API.get('fataskman', api_path, config).then(response => {
            console.log('doDownloadUsage() got file response');
            FileSaver.saveAs(response.data, filename);
        }).catch(err => {
          console.error('doDownloadUsage()', err);
        });
    }

    return (
        <div>
            <h2>Download Usage Report</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Start Date</td>
                        <td>End Date</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>
                            <input
                                id="usageStart"
                                type="text"
                                required
                                minLength="10"
                                maxLength="10"
                                placeholder="2024-12-01"
                            />
                        </td>
                        <td>
                            <input
                                id="usageEnd"
                                type="text"
                                required
                                minLength="10"
                                maxLength="10"
                                placeholder="2024-12-31"
                            />
                        </td>
                        <td>
                            <input
                                className="saveButton"
                                type="submit"
                                value="Download"
                                onClick={doDownloadUsage}/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default DownloadUsageReport