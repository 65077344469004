/*******************************************************************************
  ShowRounds.js

  Author: Advait

*******************************************************************************/

import React, { Component } from 'react'

import DownloadRoundData from './DownloadRoundData'
import '../index.css'

class ShowRounds extends Component {

  //***************************************************************************
  // render the UI
  //***************************************************************************

  render() {
    if(this.props.hideDisinfectionPage && this.props.hideInspectionPage && this.props.hideSecurityPatrolPage
    ){
      return(
        <div>
            <div className="individualContainer">
                Selected site does not support any round.
                
            </div>
          </div>
      )
    }else{
      return (
        <div>
          {
            this.props.siteid && !this.props.hideDisinfectionPage ? 
            <DownloadRoundData 
              roundType="disinfections" 
              siteid={this.props.siteid} 
              sitename={this.props.sitename}
            /> : null
          }
          {
            this.props.siteid && !this.props.hideInspectionPage ? 
            <DownloadRoundData 
              roundType="inspections" 
              siteid={this.props.siteid} 
              sitename={this.props.sitename}
            /> : null
          }      
          {
            this.props.siteid && !this.props.hideSecurityPatrolPage ? 
            <DownloadRoundData 
              roundType="patrols" 
              siteid={this.props.siteid} 
              sitename={this.props.sitename}
            /> : null
          }   
        </div>
      )
    }
  }
}
export default ShowRounds